import checkMapMixin from "../mixin/checkMapUrl";
Vue.component("information-map", {
    mixins: [checkMapMixin],
    props: {
        name: String,
        mapUrl: String,
        mapImage: String,
        styleDisplay: String,
        map: Object,
    },
    data() {
        return {
            mapProperties: {
                mapUrl: "",
                mapName: "",
                mapImage: "",
                mapLink: "",
                mapDescription: "",
                mapCopyright: "",
                tilesetCopyright: [],
            },
        };
    },
    mounted() {
        this.mapProperties.tilesetCopyright = [];
        this.mapProperties.mapUrl = this.mapUrl;
        this.getMapProperties(this.mapUrl).then(() => {
            if (this.styleDisplay === "mapCard" || this.styleDisplay === "world-form") {
                this.$emit("information-map-name", this.mapProperties.mapName);
            }
        });
    },
    methods: {
        getMapProperties(mapUrl) {
            return this.checkMapUrl(mapUrl).then((data) => {
                if (!(data.properties == undefined)) {
                    data.properties.forEach((property) => {
                        this.parseProperty(property, mapUrl);
                    });
                }
                // it used for the wam file analysis
                if(!(data.metadata == undefined)){
                    this.mapProperties.mapName = data.metadata.name;
                    this.getMapImgUrl(data.metadata.thumbnail, mapUrl).then((imgUrl) => {
                        this.mapProperties.mapImage = imgUrl;
                        this.$emit("information-map-image", imgUrl);
                    });
                    this.mapProperties.mapDescription = data.metadata.description;
                    this.mapProperties.mapLink = data.mapUrl;
                    this.mapProperties.mapCopyright = data.metadata.copyright;
                }
                data?.tilesets.forEach((tileset) => {
                    if (!(tileset.properties == undefined)) {
                        tileset.properties.forEach((property) => {
                            if (property.name === "tilesetCopyright") {
                                if (property.type === "string") {
                                    this.mapProperties.tilesetCopyright.push({
                                        name: tileset.name,
                                        value: property.value,
                                    });
                                }
                            }
                        });
                    }
                });
            });
        },
        parseProperty: function (property, mapUrl) {
            if (property.type !== "string") {
                return;
            }
            switch (property.name) {
                case "mapName":
                    this.mapProperties.mapName = property.value;
                    break;
                case "mapImage":
                    this.getMapImgUrl(property.value, mapUrl).then((imgUrl) => {
                        this.mapProperties.mapImage = imgUrl;
                        this.$emit("information-map-image", imgUrl);
                    });
                    break;
                case "mapDescription":
                    this.mapProperties.mapDescription = property.value;
                    break;
                case "mapLink":
                    this.mapProperties.mapLink = property.value;
                    break;
                case "mapCopyright":
                    this.mapProperties.mapCopyright = property.value;
                    break;
            }
        },
        getMapImgUrl: function (value, mapUrl) {
            const dirUrl = mapUrl.substr(0, mapUrl.lastIndexOf("/"));
            return this.doesFileExist(value).then((exist) => {
                if (exist) {
                    return value;
                } else {
                    let absoluteUrl = dirUrl + "/" + value;
                    return this.doesFileExist(absoluteUrl).then((exist) => {
                        if (exist) {
                            return absoluteUrl;
                        }
                    });
                }
            });
        },
        selectMap() {
            this.$emit("selectmap", this.mapProperties);
        },
    },
});
