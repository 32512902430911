export const errorProtocolMapUrlMessage = "Map URL must start with http:// or https://";
export default {
    data: () => ({
        toCheckMapUrlLoader: false,
        errorsMapUrl: null,
        selectedMapUrl: "",
    }),
    methods: {
        checkMapUrl(mapUrl, playHost = window.Spark.frontUrl) {
            if(!/^(http|https):\/\//.test(mapUrl)) {
                this.errorsMapUrl = errorProtocolMapUrlMessage;
                return Promise.reject(new Error(errorProtocolMapUrlMessage));
            }
            this.toCheckMapUrlLoader = true;
            return axios
                .get("/api/check-map", { params: { mapUrl, playHost } })
                .catch((err) => {
                    this.toCheckMapUrlLoader = false;
                    this.errorsMapUrl = "Outbound The layer named error while checking the url.";
                    throw err;
                })
                .then((res) => {
                    if (res.data.success === 1) {
                        this.toCheckMapUrlLoader = false;
                        this.errorsMapUrl = res.data.corsHeaders === [] ? "Warning: Cors headers are not set" : null;
                        this.selectedMapUrl = mapUrl;
                        return res.data.data;
                    } else {
                        if (res.data.statusCode !== 200 && res.data.statusCode !== 202) {
                            this.errorsMapUrl = "Url returns a " + res.data.statusCode + " response.";
                        } else {
                            this.errorsMapUrl = "Unknown error while checking the url.";
                        }
                        this.toCheckMapUrlLoader = false;
                        throw "Map invalid";
                    }
                });
        },
        doesFileExist(url) {
            return fetch(url)
                .then((response) => !!response.ok)
                .catch(() => false);
        },
        doesImageExist(url) {
            return fetch(url)
                .then((response) => {
                    const contentTypeHeader = response.headers.get("content-type");
                    return !!response.ok && contentTypeHeader && contentTypeHeader.startsWith("image/");
                })
                .catch((err) => {
                    console.error(err);
                    return false;
                });
        },
    },
};
