import posthog, { type PostHog } from "posthog-js";
import type { Properties } from "@workadventure/scripting-api-extra/dist";
const posthogApiKey = window.Spark.posthogApiKey;
const posthogUrl = window.Spark.posthogUrl;

declare global {
    interface Window {
        posthog: PostHog;
    }
}

class PosthogClient {
    constructor() {
        if (posthogApiKey) {
            posthog.init(posthogApiKey, { api_host: posthogUrl });
            window.posthog = posthog;
        }
    }
    identifyUser(id: string, email: string) {
        if (!posthogApiKey) return;
        posthog.identify(email, { id, email });
    }
    capture(event: string, value: Properties | undefined) {
        if (!posthogApiKey) return;
        posthog.capture(event, value);
    }
}
export const posthogClient = new PosthogClient();
